// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap-5.1.3/functions";

// 2. Include any default variable overrides here
$font-family-sans-serif:      "Catamaran", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-weight:        800;
$btn-border-radius:           100px;
$input-border-radius:         100px;
$primary:                     #71873F;
$secondary:                   #ABBE99;
$secondary-screen:            #E3EED6;
$tertiary:                    #688557;
$tertiary-screen:             #A6C590;
$darkgrey:                    #1D3737;
$notblack:                    #1C252A;
$modal-fade-transform:        translate(0, 0);
$carousel-control-color:      $secondary;
$carousel-indicator-active-bg:$primary;
$carousel-caption-color:      $notblack;


$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  xxxl: 1528px
);


// 3. Include remainder of required Bootstrap stylesheets
@import "bootstrap-5.1.3/variables";
@import "bootstrap-5.1.3/mixins";
@import "bootstrap-5.1.3/utilities";

// 4. Include any optional Bootstrap components as you like
@import "bootstrap-5.1.3/root";
@import "bootstrap-5.1.3/reboot";
@import "bootstrap-5.1.3/type";
@import "bootstrap-5.1.3/images";
@import "bootstrap-5.1.3/containers";
@import "bootstrap-5.1.3/grid";
@import "bootstrap-5.1.3/tables";
@import "bootstrap-5.1.3/forms";
@import "bootstrap-5.1.3/buttons";
@import "bootstrap-5.1.3/transitions";
@import "bootstrap-5.1.3/dropdown";
@import "bootstrap-5.1.3/button-group";
@import "bootstrap-5.1.3/nav";
@import "bootstrap-5.1.3/navbar";
@import "bootstrap-5.1.3/card";
@import "bootstrap-5.1.3/accordion";
@import "bootstrap-5.1.3/breadcrumb";
@import "bootstrap-5.1.3/pagination";
@import "bootstrap-5.1.3/badge";
@import "bootstrap-5.1.3/alert";
@import "bootstrap-5.1.3/progress";
@import "bootstrap-5.1.3/list-group";
@import "bootstrap-5.1.3/close";
@import "bootstrap-5.1.3/toasts";
@import "bootstrap-5.1.3/modal";
@import "bootstrap-5.1.3/tooltip";
@import "bootstrap-5.1.3/popover";
@import "bootstrap-5.1.3/carousel";
@import "bootstrap-5.1.3/spinners";
@import "bootstrap-5.1.3/offcanvas";
@import "bootstrap-5.1.3/placeholders";

// Helpers
@import "bootstrap-5.1.3/helpers";

// Utilities
@import "bootstrap-5.1.3/utilities/api";
// scss-docs-end import-stack

// 5. Add additional custom code here

:root {
  --bs-body-color: #1C252A;
  --bs-body-line-height: 1.9em;
}

.bg-secondary {
  background-color: $secondary;
}

.bg-secondary-light {
  background-color: tint-color($secondary, 20%);
}

.btn-primary,
.btn-primary:active,
.btn-primary:hover,
.btn-primary:focus{
  color: #fff;
}

.btn-secondary {
  color: $notblack;
}

.btn-tertiary {
    @include button-variant($tertiary, $tertiary, #fff, lighten($tertiary,5%), lighten($tertiary, 10%), #fff);
}

.btn-tertiary-primary {
    @include button-outline-variant($tertiary, #222222, lighten($tertiary,5%), $tertiary);
}

a {
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.headroom {
  will-change: transform;
  transition: transform 200ms linear;
}
.headroom--pinned {
  transform: translateY(0%);
}
.headroom--unpinned {
  transform: translateY(-100%);
}

.navbar,
.navbar button,
.btn {
  font-weight: 700;
}

.navbar.bg-light {
  background-color: rgba(255,255,255,0.95) !important;
}

.navbar-brand{
  margin: 0;
  padding: 0;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28171, 190, 153, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-toggler{
  color: $secondary;
  border: none;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 0.25rem $secondary;
}

.navbar-collapse button {
  display: block;
}

.navbar-collapse button:last-of-type {
  margin-top: 0.3em;  
}

.navbar-nav {
  margin-top: 1em;
}

.navbar.navbar-light .navbar-nav .nav-link {
  margin-left: 0.4em;
  color: $notblack;
}

.navbar-light .navbar-nav .nav-link.active {
  font-weight: 900;
}

.navbar-collapse .btn {
  padding-left: 1em;
  padding-right: 1em;
}

span.logo {
  display: inline-block;
  position: relative;
  font-size: 1.8em;
  line-height: 1.8em;
  height: 0;
  padding-top: 1em;
  overflow: hidden;
  width: 3.88em;
  background: url(../img/green-atlas.svg) 0 50% no-repeat;
  background-size: contain;
  vertical-align: text-bottom;
}

h1 {
  font-size: 3rem;
  font-weight: 800;
  line-height: 1.0em;
  margin-top: 0.1em;
  margin-bottom: 0;
}

h2 {
  font-size: 2rem;
  font-weight: 800;
  line-height: 1.2em;
  margin-bottom: 0.1em;
}

.subheading {
  font-size: 1.333333rem;
  font-weight: 100;
  line-height: 1.4em;
}

a.subheading {
  color: $notblack;
}

.collapse-heading {
  display: flex;
  align-items: center;
}

.collapse-heading::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  transition: transform 0.2s ease-in-out;
  display: block;
  float: right;
}

.collapse-heading:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23667a39'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(-180deg);
}

.byline {
  font-size: 1.5rem;
  font-weight: 100;
  margin-top: 0;
  margin-bottom: 1em;
}

body.home .byline {
  margin-top: 1em;
  margin-bottom: 0em;
}

.smallest {
  font-size: 0.75em;
  line-height: 1em;
}

.home-block{
  height: 100vw;
  text-decoration: none;
  background-color: $secondary;
  position: relative;
  overflow: hidden;
}

.home-block,
.home-block a{
  color: #fff;
}

.hero-block {
  height: 80vh;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  text-shadow: 2px 3px 20px #09220C;
}

.about-block .home-block {
  height: auto;
  min-height: 70vh
}

.home-block .text {
  margin-bottom: 1.5em;
  z-index: 10;
}

.page-list .home-block h2,
.global-block .home-block h2,
footer p.h2{
  font-size: 1.75rem;
  margin-bottom: 0.5em;
  margin-top: 0;
}

.page-list .page-description,
.global-block .home-block .byline,
footer p.byline {
  font-size: 1.083333rem;
  max-width: 80%;
  line-height: 1.3em;
  font-weight: 100;
}

.page-list .byline {
  font-size: 1.083333rem;
}

.global-block .home-block .byline {
  max-width: 530px;
}

.home-block .zoom-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  transform: scale(1.0);
//  transition: all 1s ease-in-out;
  transition: transform 2s 0s ease-in-out, opacity 2s 0s ease-in-out;
  z-index: 1;
}

a:hover.home-block {
  color: rgba(255,255,255,1);
}

a:hover.home-block .zoom-img {
  transform: scale(1.05);
  opacity: 0.5;
}

//a:hover.home-block .page-description {
//  height: 3.5em;
//  opacity: 1;
//  transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out 0.2s;
//}

body.home .home-block.hero-block,
body.cartographer .hero-block {
//  background: url(../img/hero-home.jpg) 50% 50%/cover no-repeat;
  background-color: transparent;
  background-position: 50% 100%;
}

.home-block.detail-driven .zoom-img {
  background: url(../img/detail-driven2-page-link@0.67x.jpg) 50% 50%/cover no-repeat;
}

.home-block.cartographer .zoom-img {
  background: url(../img/cartographer-page-link@0.67x.jpg) 50% 50%/cover no-repeat;
}

.home-block.applications .zoom-img {
  background: url(../img/detail-driven-page-link@0.67x.jpg) 50% 50%/cover no-repeat;
}

.home .global {
  background-color: $tertiary;
  color: rgba(166, 197, 144, 1);
//  background-image: url(../img/global.jpg);
  background-position: 100% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

a.home-block.global:hover {
  color: rgba(166, 197, 144, 0.8);
  opacity: 0.9;
}

.world-map {
  position: relative; 
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
}

.world-map img.map {
  z-index: 1;
  width: 100%;
}

.world-map img.pin {
  position: absolute;
  z-index: 10;
  width: 2%;
  height: auto;
}

.home .global {
  z-index: 10;
}

.home .global .world-map {
  position: absolute;
  bottom: 2em;
  left: 2em;
  width: 105%;
  z-index: -1;
}

.home .about-block .home-block{
  color: $notblack;
  background-color: $white;
}

a.home-block.about:hover {
  color: rgba(227, 238, 214, 0.8);
  opacity: 0.9;
}

span.arrow {
  display: inline-block;
  position: relative;
  height: 0.8em;
  width: 1.527272em;
  top: 0.1em;
  background-image: url(../img/arrow-right-white.svg);
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 0.5s ease-in-out;
}

span.arrow-notblack {
  background-image: url(../img/arrow-right-notblack.svg);
}

span.arrow-left {
  transform: rotate(180deg);
}

span.arrow.arrow-secondary-light-screen{
  background-image: url(../img/arrow-right-secondary-light-screen.svg);
}

span.arrow.arrow-secondary-screen{
  background-image: url(../img/arrow-right-secondary-screen.svg);
}

span.arrow.arrow-tertiary-screen{
  background-image: url(../img/arrow-right-tertiary-screen.svg);
}

a:hover span.arrow {
  margin-left: 0.5em;
  //opacity: 0.8;
}

a:hover span.arrow.arrow-left {
  margin-right: 0.5em;
  margin-left: 0;
}

.content-row {
  @extend .row;
  @extend .my-5;
  @extend .py-5;
}

.content-image-row {
  @extend .row;
  @extend .d-flex;
  @extend .align-items-center;
  @extend .my-5;
  @extend .py-5;
}

.content-text {
  @extend .col-lg-6;
  @extend .mt-3;
  @extend .mt-lg-0;
  @extend .pe-lg-5;
}

.content-text p:last-child {
  margin-bottom: 0;
}

.content-image {
  @extend .col-lg-6;
  @extend .mb-3;
  @extend .mb-lg-0;
  @extend .ps-lg-5;
  @extend .order-lg-last;
}

.content-image-left {
  @extend .col-lg-6;
  @extend .mb-3;
  @extend .mb-lg-0;
  @extend .pe-lg-5;
}

.content-image-left + .content-text {
  @extend .ps-lg-5;
  padding-right: calc(var(--bs-gutter-x) * .5)!important;
}

.carousel-nav .nav-item .nav-link {
  color: $notblack;
  font-weight: 800;
  border-radius: 100px;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}

.carousel-nav .nav-item.active .nav-link {
  color: $white;
  background-color: $secondary;
}

.carousel-item {
  background-color: $white;
}

.page .global {
  color: $secondary-screen;
  background-color: $secondary;
}

.break-container{
  width:100vw;
  position:relative;
  left:calc(-1 * (100vw - 100%)/2);
}

.cartographer-wrapper {
  position: relative;
}

.hotspot {
  display: block;
  position: absolute;
  width: 20px;
  height: 0;
  overflow: hidden;
  padding-top: 20px;
  border-radius: 10px;
  background-color: $secondary;
  box-shadow: 0 0 0 0 rgba(171, 190, 153, 0.7);
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

@keyframes pulse {to {box-shadow: 0 0 0 35px rgba(171, 190, 153, 0);}}

.hotspot11 {
  top: 10%;
  right: 52%;
}

.hotspot12 {
  top: 41%;
  right: 26%;
}

.hotspot13 {
  top: 19%;
  right: 35%;
}

.hotspot14 {
  top: 35%;
  right: 36%;
}

.hotspot21 {
  top: 13%;
  right: 60%;
}

.hotspot22 {
  top: 32%;
  right: 35%;
}

.hotspot23 {
  top: 21%;
  right: 31%;
}

.hotspot24 {
  top: 8%;
  right: 46%;
}

.hotspot31 {
  top: 32%;
  right: 45%;
}

.hotspot32 {
  top: 45%;
  right: 21%;
}

.hotspot33 {
  top: 18%;
  right: 55%;
}

.hotspot34 {
  top: 69%;
  right: 16%;
}

#hotspot1 .modal-header,
#hotspot2 .modal-header,
#hotspot3 .modal-header,
#hotspot4 .modal-header {
  position: absolute;
  right: 0;
  z-index: 10;
}

#hotspot1 .modal-body p:last-of-type, 
#hotspot2 .modal-body p:last-of-type,
#hotspot3 .modal-body p:last-of-type,
#hotspot4 .modal-body p:last-of-type {
  margin-bottom: 0;
}

#cartographerCarousel {
  z-index: 1;
}

#cartographerController {
  z-index: 10;
}

.table tbody > tr:last-of-type > * {
  border-bottom-width: 0;
}

.page-links a,
a.explore {
  color: $notblack;
  background-color: mix(white, $secondary, 91);
  border-radius: 50px;
}

.page-links a:hover,
a.explore:hover {
  opacity: 0.6;
}

.page-links p,
a.explore p {
  margin: 0;
  line-height: 1em;
}

.page-links .previous p:not(.title){
  margin-left: 3.6em;
  transition: all 0.5s ease-in-out;
}

.page-links a.previous:hover p:not(.title){
  margin-left: 4.6em;
}

.page-links p.title,
a.explore p.title {
  font-weight: 800;
  font-size: 2em;
  margin-top: 0.1em;
}

.cta-schedule p,
.cta-partner p {
  margin-top: 1em;
  margin-bottom: 0;
}

.cta-schedule h1,
.cta-partner h1 {
  margin-top: 0;
  margin-bottom: 0.6em;
}

.cta-schedule p,
.cta-schedule h1 {
  color: #E9F2DE;
  transition: all 0.2s ease-in-out;
}

.cta-partner p,
.cta-partner h1 {
  color: #E3EED6;
  transition: all 0.2s ease-in-out;
}

a:hover.cta-partner p,
a:hover.cta-partner h1,
a:hover.cta-schedule p,
a:hover.cta-schedule h1{
  opacity: 0.8;
}

a:hover.cta-partner,
a:hover.cta-schedule {
  opacity: 0.98;
}

footer {
  background-color: $darkgrey;
  border-bottom: 1px solid $darkgrey;
}

footer .logo-wrapper {
  display: inline-block;
  padding: 1em 1.5em 1em 1em;
  background-color: #fff;
  border-radius: 100px;
}


footer ul {
  list-style: none;
  margin: 0;
  padding: 1em 0.5em;
}

footer ul li{
  margin: 0;
  padding: 0;
}

footer ul li a.nav-link  {
  margin: 0;
  padding: 0.1em 0;
  color: $secondary;
  font-weight: 200;
  line-height: 1.5em;
}

footer ul li a.nav-link.active {
  font-weight: 900;
}

footer input.email {
  margin-right: -3em;
  margin-left: auto; 
  padding-right: 4em !important;
  border: 1px solid #fff;
  background-clip: initial;
}

footer .email-icon {
  display: inline-block;
  width: 2em;
  height: 2em;
  border-radius: 100px;
  background-color: $tertiary;
  color: $white;
  font-weight: 600;
  text-align: center;
  margin-right: 1em;
}

footer a.email-link {
  font-weight: 600;
  color: $secondary;
  position: relative;
  top: 0.1em;
}

footer .terms {
  background-color: #162929;
}

footer .terms p {
  margin-top: 0.1em;
  margin-bottom: 0.1em;
}

footer .terms a {
  color: rgba(255,255,255,0.5); 
  font-size: 0.8rem;
  margin-left: 0.4em;
}

footer .terms a:hover{
  color: rgba(255,255,255,1);
}

#partnerModal .modal-content {
  color: $tertiary-screen;
  background-color: $tertiary;
}

#scheduleModal .modal-content {
  color: $secondary-screen;
  background-color: $secondary;
}

.modal-header,
.modal-footer {
  border: none;
}

#partnerModal .modal-header,
#scheduleModal .modal-header {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 10;
}

#partnerModal .modal-header .btn-close,
#scheduleModal .modal-header .btn-close {
  padding: 1rem;
  border-radius: 100px;
  background: $secondary-screen url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23688557'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}

#partnerModal .modal-footer,
#scheduleModal .modal-footer {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

#partnerModal .modal-content .byline {
  font-size: 1rem;
  margin-bottom: 0.2em;
}

#partnerModal .modal-content a {
  color: $white;
}

#partnerModal .modal-content address {
  margin-bottom: 0;
}

#partnerModal .modal-content .partner {
  line-height: 1.2em;
  margin-top: 1em;
  margin-bottom: 1em;
}


img.img-fluid {
  border-radius: 75px;
}

img.small-rad {
  border-radius: 25%!important;
}

//.page .content-container .row {
//  margin-top: 100px!important;
//}

.max-width,
.text-max-width,
.max-width-800 {
  max-width: 1120px;
  margin-left: auto;
  margin-right: auto;
}

.text-max-width {
  max-width: 1000px;
}

.text-center .text-max-width {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.max-width p {
  max-width: 880px;
}

.max-width-800 {
  max-width: 800px;
}

.max-80 {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

#caseStudies .input-group {
  background-color: tint-color($secondary, 90%);
  border-radius: 100px;
  padding: 0.5rem;
}

#caseStudies .input-group-text {
  border-radius: 100px;
  background-color: $secondary;
  color: $white;
  font-weight: 800;
  padding-left: 1.5em;
  padding-right: 1.5em;
  border: none;
  z-index: 10;
}

#caseStudies select {
  background-color: tint-color($secondary, 90%);
  border: none;
  border-radius: 100px;
  z-index: 1;
  font-weight: 800;
  margin-left: 0.5rem;
}

.case-study {
  padding-bottom: 4em;
}

.case-study .label {
  font-weight: 100;
}

.case-study .title {
  font-weight: 900;
}



@media screen and (min-width: 768px){
  .hero-block h1 {
    max-width: 700px;
  }
  
  .home-block.detail-driven .zoom-img {
    background: url(../img/detail-driven2-page-link.jpg) 50% 50%/cover no-repeat;
  }

  .home-block.cartographer .zoom-img {
    background: url(../img/cartographer-page-link.jpg) 50% 50%/cover no-repeat;
  }

  .home-block.applications .zoom-img {
    background: url(../img/detail-driven-page-link.jpg) 50% 50%/cover no-repeat;
  }

}

@media screen and (min-width: 992px){
  .navbar-collapse button:last-of-type {
    margin-top: 0em;
    margin-left: 0.4em;
  }
    
  ul.navbar-nav {
    margin-right: 0.5em;
    margin-top: 0;
  }
  
  .home-block{
    height: 66vh;
  }
  
  .hero-block {
    height: 90vh;
  }
  
  .page-list .home-block {
    height: 33.33vw;
  }
  
  .home .global .world-map {
    width: 80%;
    left: auto;
    right: 2em;
  }
  
  #systemViewsCarousel,
  #processCarousel {
    margin-top: -8em!important;
    z-index: -1;
  }
  
  #systemViewsController,
  #cartographerController,
  #processController {
    margin-top: -5em!important;
  }
  
  footer ul li a.nav-link  {
    font-size: 16px;
    line-height: 1.2em;
  }
  
  footer form.subscribe input,
  footer form.subscribe button {
    font-size: 20px;
  }
  
  footer input.email {
    max-width: 400px;
  }
  
  .lg-max-80 {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .collapse-heading {
    pointer-events: none;
  }
  
  .collapse-heading::after {
    display: none;
  }
  
  .collapse.content-collapse:not(.show) {
    display: initial;
  }
}

@media screen and (min-width: 1400px) {
  html {
    font-size: 20px;
  }
  
  .navbar-collapse,
  .navbar-collapse .btn {
    font-size: 20px;
  }
  
  h1 {
    font-size: 3.6rem;
  }
  
  .byline {
    font-size: 1.8rem;
  }
  
  h2 {
    font-size: 2.4rem;
  }
  
  .subheading { 
    font-size: 1.6rem;
  }
  
  .page-list .home-block h2,
  .global-block .home-block h2,
  footer p.h2{
    font-size: 2.1rem;
  }

  .page-list .page-description,
  .global-block .home-block .byline,
  footer p.byline,
  .page-list .byline {
    font-size: 1.3rem;
  }
  
  img.img-fluid {
    border-radius: 150px;
  }
  
  .page-links a,
  a.explore {
    border-radius: 75px;
  }
  
  .home .global .world-map {
    max-width: 50%;
  }
}
